import React, { Component } from 'react';
import './App.css';
const va_token = 'dksvsr7JTDduFTDMNPqKAh2XZEmcAb';

class App extends Component {
	state = {
		displayStorageFrame: false,
		user: undefined,
		pw: undefined
	};
	onSubmit = () => {
		console.log('submit');
		const { user, pw } = this.state;
		if (user === 'ashrit' && pw === 'dcodesupport2019') {
			this.setState({ displayStorageFrame: true });
		}
	};
	handleChange = e => {
		const { name, value } = e.target;
		const myState = this.state;
		myState[name] = value;
		this.setState(myState);
	};
	redirect = () => {
		console.log('redirecting');
		window.location.href =
			'https://interface.dev.flomllr.com/?shop=codeincart2.myshopify.com';
	};
	render = () => {
		const { displayStorageFrame } = this.state;
		const storageFrame = (
			<iframe
				src={'https://interface.dev.flomllr.com/?va_token=' + va_token}
				title='storageFrame'
				width={1}
				height={1}
				id='storageFrame'
				onLoad={this.redirect}
			/>
		);
		return (
			<div className='App'>
				<h2>Dcode Admin Login</h2>
				<input
					name='user'
					placeholder='Username'
					onChange={this.handleChange}
				/>
				<input
					type='password'
					name='pw'
					placeholder='Password'
					onChange={this.handleChange}
				/>
				<button onClick={this.onSubmit}>Login</button>
				{displayStorageFrame && storageFrame}
			</div>
		);
	};
}

export default App;
